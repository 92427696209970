import React, { useState } from 'react'

const Primeros = () => {

    const [seccion, setSeccion] = useState('')
  
    const segundodoA = () =>{
      setSeccion('1roA')
    }
    const segundodoB = () =>{
      setSeccion('1roB')
    }
    const segundodoC = () =>{
      setSeccion('1roC')
    }
    const segundodoD = () =>{
      setSeccion('1roD')
    }
    const segundodoE = () =>{
      setSeccion('1roE')
    }
    const segundodoF = () =>{
      setSeccion('1roF')
    }
    const segundodoH = () =>{
      setSeccion('1roH')
    }
    const segundodoG = () =>{
      setSeccion('1roG')
    }
    
    return (
      <div>
        <h1>Selecciona División </h1>
      
          <div className='btn' onClick={segundodoA}>
              1ro A
          </div>
          <div className='btn' onClick={segundodoB}>
              1ro B
          </div>
          <div className='btn' onClick={segundodoC}>
              1ro C
          </div>        
          <div className='btn' onClick={segundodoD}>
              1ro D
          </div>        
          <div className='btn' onClick={segundodoE}>
              1ro E
          </div>
          <div className='btn' onClick={segundodoF}>
              1ro F
          </div>
          <div className='btn' onClick={segundodoG}>
              1ro G
          </div>
          <div className='btn' onClick={segundodoH}>
              1ro H
          </div>

        {seccion === '' ? (
            <p></p>
        ) : (
          
          <div class="iframe-container">
            {seccion === '1roA' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ9ynXjWRMG-VoO72kB3uY-FsMBdLYuK_0ZzJB2pWjFdhCqSQrStR7rMGTNmMHl7NowmedtXfZw1O2/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"
            ></iframe>
            }
            
            {seccion === '1roB' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ9ynXjWRMG-VoO72kB3uY-FsMBdLYuK_0ZzJB2pWjFdhCqSQrStR7rMGTNmMHl7NowmedtXfZw1O2/pubhtml?gid=1225827684&amp;single=true&amp;widget=true&amp;headers=false"
              ></iframe>
            }
            
            {seccion === '1roC' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ9ynXjWRMG-VoO72kB3uY-FsMBdLYuK_0ZzJB2pWjFdhCqSQrStR7rMGTNmMHl7NowmedtXfZw1O2/pubhtml?gid=2036829728&amp;single=true&amp;widget=true&amp;headers=false"
              ></iframe>
            }
      
            {seccion === '1roD' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ9ynXjWRMG-VoO72kB3uY-FsMBdLYuK_0ZzJB2pWjFdhCqSQrStR7rMGTNmMHl7NowmedtXfZw1O2/pubhtml?gid=272920861&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                      }
            
            {seccion === '1roE' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ9ynXjWRMG-VoO72kB3uY-FsMBdLYuK_0ZzJB2pWjFdhCqSQrStR7rMGTNmMHl7NowmedtXfZw1O2/pubhtml?gid=187613697&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                      }
            
            {seccion === '1roF' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ9ynXjWRMG-VoO72kB3uY-FsMBdLYuK_0ZzJB2pWjFdhCqSQrStR7rMGTNmMHl7NowmedtXfZw1O2/pubhtml?gid=826658590&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                      }
            {seccion === '1roG' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ9ynXjWRMG-VoO72kB3uY-FsMBdLYuK_0ZzJB2pWjFdhCqSQrStR7rMGTNmMHl7NowmedtXfZw1O2/pubhtml?gid=370420530&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

            }
            {seccion === '1roH' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ9ynXjWRMG-VoO72kB3uY-FsMBdLYuK_0ZzJB2pWjFdhCqSQrStR7rMGTNmMHl7NowmedtXfZw1O2/pubhtml?gid=842479711&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

            }
        </div>
        
      )}
  
        </div>
  )
}

export default Primeros