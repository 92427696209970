import React, { useState } from 'react'
import Segundos from './horariocursos/Segundos'
import Primeros from './horariocursos/Primeros'
import Terceros from './horariocursos/Terceros'
import Cuartos from './horariocursos/Cuartos'
import Quintos from './horariocursos/Quintos'

const HorariosCursos = () => {

    const [seccion, setSeccion] = useState('')

    const primeros = () =>{
        setSeccion('primeros')
    }
    const segundos = () =>{
        setSeccion('segundos')
    }
    const terceros = () =>{
        setSeccion('terceros')
    }
    const cuartos = () =>{
        setSeccion('cuartos')        
    }
    const quintos = () =>{
        setSeccion('quintos')        
    }


  return (
    <div className='seccion'>
        <h1>Seleccionar Curso</h1>
        <div className='btn' onClick={primeros}>
            Primeros
        </div>
        <div className='btn' onClick={segundos}>
            Segundos
        </div>
        <div className='btn' onClick={terceros}>
            Terceros
        </div>
        <div className='btn' onClick={cuartos}>
            Cuartos
        </div>
        <div className='btn' onClick={quintos}>
            Quintos
        </div> 

        {seccion === 'primeros' && (
            <Primeros/>
        )}
        
        {seccion === 'segundos' && (
            <Segundos/>
        )}
        
        {seccion === 'terceros' && (
            <Terceros/>
        )}
        
        {seccion === 'cuartos' && (
            <Cuartos/>
        )}
        
        {seccion === 'quintos' && (
            <Quintos/>
        )}

    </div>
  )
}

export default HorariosCursos