import React, { Component } from 'react';
import Header from './Header';
import Inicio from './Menus/Inicio';
import Publicaciones from './Informacionphp/publicaciones';

class Cpem41 extends Component {
    render() {
        return (
            <div>                
                <Header/>

                {/* <Inicio/> */}
                <Publicaciones/>

            </div>
        );
    }
}

export default Cpem41;
