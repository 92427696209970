import React from 'react';
import HorasTomadas from './HorasTomadas';

const NoticiasDocentes = () => {


    return (
        <div className='seccion'>
            
            <h1>¿Tomas Horas o un cargo?</h1>
            <h4>
                Por favor, regístrate en el siguiente enlace: 
                <br/>
                <a href="https://forms.gle/L2uvZg5FbjFwJoZm8" target="_blank"   > Formulario de Registro  </a>
            </h4>
            
            <br/>

            <h4>
                Comunícate dentro de las 48 horas con la escuela a través del correo oficial 
                <br/>
                <a href="mailto:cpem41secretaria@gmail.com"> cpem41secretaria@gmail.com </a> 
                <br/>
                adjuntando la documentación necesaria según tu situación:
            </h4>

            <br/>

            <HorasTomadas/>

        </div>
    );
}

export default NoticiasDocentes;
