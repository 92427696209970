import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Listapublicaciones from './listapublicaciones';

const baseURL = 'https://www.cpem41.edu.ar/backend.php/admpost.php';

//const baseURL = 'http://localhost/cpem41-02/backend.php/subirpost.php';

const Publicaciones = () => {
    const [informacion, setInformacion] = useState([]);
    const [VisibleCont, setVisibleCont] = useState(4)

    useEffect(() => {
        getInformacion();
    }, []);
 
    const getInformacion = async () => {
        try {
            const response = await axios.get(baseURL);
            const reversedData = response.data.slice().reverse()
            setInformacion(reversedData) 
        } catch (error) {
            console.error('Error al obtener la información:', error)
        }
    }

    const mostrarMas = () => {
        setVisibleCont(prevCount => prevCount + 4)
    }

    return (
        <div>
            <div className='informacion container'>
                <h2>INFORMACION INSTITUCIONAL</h2>
                <div className='informacion_container'>
                {informacion.slice(0, VisibleCont).map(item => (
                        <Listapublicaciones
                            key={item.id}
                            titulo={item.titulo}
                            descripcion={item.descripcion}
                            img={item.img}
                            link={item.link}
                            finalI={item.finalI}
                            createdAt={item.createdAt}
                        />
                    ))}
                </div>
                {VisibleCont < informacion.length && (
                    <div onClick={mostrarMas} className='btn'>
                        Mostrar más
                    </div>
                )}
            </div>
        </div>
    );
};

export default Publicaciones;
