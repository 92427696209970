import React, { useState } from 'react'

const Egresados = () => {


    const [seccion, setSeccion] = useState ()

    const cargarTitulo = () =>{
        setSeccion('titulos')
    }

    const cargarSolicitudTitulo = () => {
        setSeccion('solicitud')
    }

  return (
    <div className='seccion_menu'>
        <div className='seccion'>
                <h1> BIENVENIDOS EGRESADOS </h1>

                    <h4>¿Que Necesitas?</h4>

                    <div className='btn' onClick={cargarTitulo}>Ver Titulos</div>
                    <div className='btn' onClick={cargarSolicitudTitulo}>Solicitar tu Titulo</div>
        

        
            {seccion === 'titulos' && (
                <div className="iframe-container">
                    <iframe className='frames'  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSdX_6HtPUb_eMchkBWeoTVLKfEZrsV2eHgy0Jjcqaf8FkPddcnWvJ-t874TySi_w/pubhtml?gid=2114919608&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                </div>
            )}
            {seccion === 'solicitud' && (
                <div className='iframe-container'>
                    <iframe className='frames' src="https://docs.google.com/forms/d/e/1FAIpQLSfHBLV7UOTptey2Ge75G3_3lqpNpk5dBB0LPmiffrznbxakNA/viewform?embedded=true" width="640" height="1206" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
                </div>
            )}
        </div>

    </div>
  )
}

export default Egresados