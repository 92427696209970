import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Consulta = () => {

    /* const gethorario = async () => {
        try {
            const response = await axios.get (baseURL)
            sethorario(response.data);
        } catch (error) {
            console.error('error al obtener la horario', error)
        }
    } */

    return (

        <div className='seccion'>

            <h1>HORARIOS DE CONSULTA</h1>
            <div class="iframe-container">
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSkhRlF0nINjot2VHjA_4Xvi0OLBm0L_fjgT6awwJkRY4H6Nmfr8RlIPEEeAdIu4Q/pubhtml?widget=true&amp;headers=false"
                >
            </iframe>
            </div>
        </div>
    );
}

export default Consulta;
