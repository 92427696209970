import React, { useState } from 'react'

const Cuartos = () => {

    const [seccion, setSeccion] = useState('')
  
    const segundodoA = () =>{
      setSeccion('4toA')
    }
    const segundodoB = () =>{
      setSeccion('4toB')
    }
    const segundodoC = () =>{
      setSeccion('4toC')
    }
    const segundodoD = () =>{
      setSeccion('4toD')
    }
    
    return (
      <div  >
        <h1>Selecciona División </h1>
      
          <div className='btn' onClick={segundodoA}>
              4to A
          </div>
          <div className='btn' onClick={segundodoB}>
              4to B
          </div>
          <div className='btn' onClick={segundodoC}>
              4to C
          </div>  
          <div className='btn' onClick={segundodoD}>
              4to D
          </div>           
  
  
          {seccion === '' ? (
            <p></p>
        ) : (
          <div class="iframe-container">
            {seccion === '4toA' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTLFzuChcDnWEaryPufNTPnaOqB0mR4LcRK1717L-tMChYmlXZcNRvofVsEcM-LgEtfeRN7C4sH0AeE/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

            }
            
            {seccion === '4toB' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTLFzuChcDnWEaryPufNTPnaOqB0mR4LcRK1717L-tMChYmlXZcNRvofVsEcM-LgEtfeRN7C4sH0AeE/pubhtml?gid=335905109&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

            }
            
            {seccion === '4toC' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTLFzuChcDnWEaryPufNTPnaOqB0mR4LcRK1717L-tMChYmlXZcNRvofVsEcM-LgEtfeRN7C4sH0AeE/pubhtml?gid=76372038&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

            }
            
            {seccion === '4toD' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTLFzuChcDnWEaryPufNTPnaOqB0mR4LcRK1717L-tMChYmlXZcNRvofVsEcM-LgEtfeRN7C4sH0AeE/pubhtml?gid=145119388&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
            }
          </div>
        )}
  
  
        </div>
  )
}

export default Cuartos