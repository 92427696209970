import React, { useState } from 'react'

const Segundos = () => {

  const [seccion, setSeccion] = useState('')

  const segundodoA = () =>{
    setSeccion('2doA')
  }
  const segundodoB = () =>{
    setSeccion('2doB')
  }
  const segundodoC = () =>{
    setSeccion('2doC')
  }
  const segundodoD = () =>{
    setSeccion('2doD')
  }
  const segundodoE = () =>{
    setSeccion('2doE')
  }
  const segundodoH = () =>{
    setSeccion('2doH')
  }
  const segundodoF = () =>{
    setSeccion('2doF')
  }
  const segundodoG = () =>{
    setSeccion('2doG')
  }
  
  
  return (
    <div>
      <h1>Selecciona División </h1>
    
        <div className='btn' onClick={segundodoA}>
            2do A
        </div>
        <div className='btn' onClick={segundodoB}>
            2do B
        </div>
        <div className='btn' onClick={segundodoC}>
            2do C
        </div>        
        <div className='btn' onClick={segundodoD}>
            2do D
        </div>        
        <div className='btn' onClick={segundodoE}>
            2do E
        </div>
        <div className='btn' onClick={segundodoH}>
            2do H
        </div>
        <div className='btn' onClick={segundodoF}>
            2do F
        </div>
        <div className='btn' onClick={segundodoG}>
            2do G
        </div>

        {seccion === '' ? (
            <p></p>
        ) : (
        <div class="iframe-container">
          {seccion === '2doA' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTEgg1cyCQ3vnb-AXbf0F5eeRt6Kf1fUl9jy6sMHYjdlwxsQ2J1onnUzf2D9W1Kp8YovFV6GW8W2hxz/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"
            >
            </iframe>
          }
          
          {seccion === '2doB' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTEgg1cyCQ3vnb-AXbf0F5eeRt6Kf1fUl9jy6sMHYjdlwxsQ2J1onnUzf2D9W1Kp8YovFV6GW8W2hxz/pubhtml?gid=72345889&amp;single=true&amp;widget=true&amp;headers=false"
            >
            </iframe>
          }
          
          {seccion === '2doC' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTEgg1cyCQ3vnb-AXbf0F5eeRt6Kf1fUl9jy6sMHYjdlwxsQ2J1onnUzf2D9W1Kp8YovFV6GW8W2hxz/pubhtml?gid=470489924&amp;single=true&amp;widget=true&amp;headers=false"
            >
            </iframe>
          }

          {seccion === '2doD' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTEgg1cyCQ3vnb-AXbf0F5eeRt6Kf1fUl9jy6sMHYjdlwxsQ2J1onnUzf2D9W1Kp8YovFV6GW8W2hxz/pubhtml?gid=485314226&amp;single=true&amp;widget=true&amp;headers=false"
           >
            </iframe>
          }
          
          {seccion === '2doE' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTEgg1cyCQ3vnb-AXbf0F5eeRt6Kf1fUl9jy6sMHYjdlwxsQ2J1onnUzf2D9W1Kp8YovFV6GW8W2hxz/pubhtml?gid=2099494004&amp;single=true&amp;widget=true&amp;headers=false"
                      >            </iframe>
          }
          
          {seccion === '2doH' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTEgg1cyCQ3vnb-AXbf0F5eeRt6Kf1fUl9jy6sMHYjdlwxsQ2J1onnUzf2D9W1Kp8YovFV6GW8W2hxz/pubhtml?gid=660884583&amp;single=true&amp;widget=true&amp;headers=false"
           >
            </iframe>
          }
          {seccion === '2doF' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTEgg1cyCQ3vnb-AXbf0F5eeRt6Kf1fUl9jy6sMHYjdlwxsQ2J1onnUzf2D9W1Kp8YovFV6GW8W2hxz/pubhtml?gid=1247738458&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

          }
          {seccion === '2doG' && 
            <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTEgg1cyCQ3vnb-AXbf0F5eeRt6Kf1fUl9jy6sMHYjdlwxsQ2J1onnUzf2D9W1Kp8YovFV6GW8W2hxz/pubhtml?gid=1759714129&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

          }
        </div>

        )}

      </div>
  )
}

export default Segundos