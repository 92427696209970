import React, { useState } from 'react'

const Terceros = () => {

    const [seccion, setSeccion] = useState('')
  
    const segundodoA = () =>{
      setSeccion('3roA')
    }
    const segundodoB = () =>{
      setSeccion('3roB')
    }
    const segundodoC = () =>{
      setSeccion('3roC')
    }
    const segundodoD = () =>{
      setSeccion('3roD')
    }
    const segundodoE = () =>{
      setSeccion('3roE')
    }
    
    
    return (
      <div>
        <h1>Selecciona División </h1>
      
          <div className='btn' onClick={segundodoA}>
              3ro A
          </div>
          <div className='btn' onClick={segundodoB}>
              3ro B
          </div>
          <div className='btn' onClick={segundodoC}>
              3ro C
          </div>        
          <div className='btn' onClick={segundodoD}>
              3ro D
          </div>  
          <div className='btn' onClick={segundodoE}>
              3ro E
          </div>        
      
          {seccion === '' ? (
            <p></p>
            ) : (
          <div class="iframe-container">
            {seccion === '3roA' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpg3SOUDR9E7snrwlg6ekxg7KXJ8yjrR1HZ8pdfdZDsvgOuYqQbz_s68su1Jwcxwjya8Q27tSdY1g2/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                      }
            
            {seccion === '3roB' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpg3SOUDR9E7snrwlg6ekxg7KXJ8yjrR1HZ8pdfdZDsvgOuYqQbz_s68su1Jwcxwjya8Q27tSdY1g2/pubhtml?gid=1181204969&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
            }
                    
            
            {seccion === '3roC' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpg3SOUDR9E7snrwlg6ekxg7KXJ8yjrR1HZ8pdfdZDsvgOuYqQbz_s68su1Jwcxwjya8Q27tSdY1g2/pubhtml?gid=1063183783&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                      }
      
            {seccion === '3roD' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpg3SOUDR9E7snrwlg6ekxg7KXJ8yjrR1HZ8pdfdZDsvgOuYqQbz_s68su1Jwcxwjya8Q27tSdY1g2/pubhtml?gid=592882307&amp;single=true&amp;widget=true&amp;headers=false"></iframe>        }
          
          
              {seccion === '3roE' && (
                <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpg3SOUDR9E7snrwlg6ekxg7KXJ8yjrR1HZ8pdfdZDsvgOuYqQbz_s68su1Jwcxwjya8Q27tSdY1g2/pubhtml?gid=449461253&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                        
              )}
            </div>
            )}


  
  
        </div>
  )
}

export default Terceros