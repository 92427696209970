import React, { useState } from 'react'

const Quintos = () => {

    const [seccion, setSeccion] = useState('')
  
    const segundodoA = () =>{
      setSeccion('5toA')
    }
    const segundodoB = () =>{
      setSeccion('5toB')
    }
    const segundodoC = () =>{
      setSeccion('5toC')
    }
    
    
    return (
      <div  >
        <h1>Selecciona División </h1>
      
          <div className='btn' onClick={segundodoA}>
              5to A
          </div>
          <div className='btn' onClick={segundodoB}>
              5to B
          </div>
          <div className='btn' onClick={segundodoC}>
              5to C
          </div>        
  
          {seccion === '' ? (
            <p></p>
        ) : (
          <div class="iframe-container">

            {seccion === '5toA' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSFHUewHAPHLWzMJtfSxcKEO0nZefETewCU-yG9fBujxlpec8bSsJ1jbGg_fKIQ2l6qjuJG3YZz7yc6/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

            }
            
            {seccion === '5toB' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSFHUewHAPHLWzMJtfSxcKEO0nZefETewCU-yG9fBujxlpec8bSsJ1jbGg_fKIQ2l6qjuJG3YZz7yc6/pubhtml?gid=335905109&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

            }
            
            {seccion === '5toC' && 
              <iframe className='frames' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSFHUewHAPHLWzMJtfSxcKEO0nZefETewCU-yG9fBujxlpec8bSsJ1jbGg_fKIQ2l6qjuJG3YZz7yc6/pubhtml?gid=76372038&amp;single=true&amp;widget=true&amp;headers=false"></iframe>

            }
          </div>
        )}
  
        </div>
  )
}

export default Quintos